/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
// eslint-disable-next-line import/no-extraneous-dependencies

import PageHeader from '../components/PageHeader'
import PostSection from '../components/PostSection'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */

// Export Template for use in CMS preview
const BlogIndexTemplate = function ({ data }) {
  const { title, featuredImage, subTitle } = data.directus.eventPage
  const { events: posts } = data.directus
  let filteredPosts = []
  let forthComingPosts = []
  let pinnedEvents = []

  pinnedEvents = posts.filter((i) => {
    return i.pinned
  })
  pinnedEvents.sort((a, b) => {
    return new Date(a.eventDate) - new Date(b.eventDate)
  })

  forthComingPosts = posts.filter((i) => {
    const now = new Date()
    const sm = new Date()
    now.setDate(now.getDate() + 7)
    now.setUTCHours(0, 0, 0, 0)
    sm.setDate(now.getDate() + 7)
    sm.setMonth(now.getMonth() + 1)
    const dt = Date.parse(i.eventDate)
    return dt > now && dt < sm && !i.pinned
  })
  forthComingPosts.sort((a, b) => {
    return new Date(a.eventDate) - new Date(b.eventDate)
  })

  filteredPosts = posts.filter((i) => {
    const now = new Date()
    const rightNow = new Date()
    rightNow.setUTCHours(0, 0, 0, 0)
    now.setDate(now.getDate() + 7)
    const dt = Date.parse(i.eventDate)
    return dt < now && dt >= rightNow && !i.pinned
  })
  filteredPosts.sort((a, b) => {
    return new Date(a.eventDate) - new Date(b.eventDate)
  })

  return (
    <main className="Blog">
      <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />

      {pinnedEvents.length > 0 && (
        <div>
          <section className="section">
            <div className="container">
              <h2>Pinned Events</h2>
              <PostSection posts={pinnedEvents} />
            </div>
          </section>
        </div>
      )}
      {filteredPosts.length > 0 && (
        <div>
          <section className="section">
            <div className="container">
              <h2>Latest News</h2>
              <PostSection posts={filteredPosts} />
            </div>
          </section>
        </div>
      )}
      {forthComingPosts.length > 0 && (
        <div>
          <section className="section">
            <div className="container">
              <h2>Later Events</h2>
              <PostSection posts={forthComingPosts} />
            </div>
          </section>
        </div>
      )}
    </main>
  )
}

// Export Default BlogIndex for front-end
const BlogIndex = function ({ data }) {
  return (
    <Layout
      meta={data.directus.eventPage.SEO || false}
      title={data.directus.eventPage.SEO.title || false}
    >
      <BlogIndexTemplate data={data} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    directus {
      eventPage {
        title
        subTitle
        featuredImage {
          id
        }
        SEO {
          title
          description
          image {
            id
          }
        }
      }
      events {
        pinned
        SEO {
          image {
            id
          }
          title
          description
        }
        shortDescription
        title
        featuredImage {
          id
        }
        body
        eventDate
        slug
      }
    }
  }
`
